<template>
    <div>
        <!-- Title and Logo -->
        <h-retribusi />
        <!-- end Logo and Title -->
        <panel title="KODE BAYAR" class="panel panel-success">
            <!-- Judul -->
            <div>
                <b-row>
                    <b-col md="12">
                        <h4>Kode Bayar</h4>
                        <p class="text-justify">
                            <b>Kode Bayar</b> adalah kode unik yang digunakan
                            untuk melakukan pembayaran retribusi daerah. Kode
                            Bayar secara otomatis tercantum pada SKRD. Apabila
                            Kode Bayar telah lewat masa aktif, maka harus
                            dibuatkan Kode Bayar baru untuk melakukan
                            pembayaran.
                        </p>
                    </b-col>
                    <!-- <b-col md="4">
                        <div class="float-right">
                            <span class="px-2">
                                <i class="fa fa-info-circle fa-2x"></i>
                            </span>
                            <span class="h4">PEMBAYARAN SSRD</span>
                        </div>
                    </b-col> -->
                </b-row>
            </div>
            <hr />
            <!-- end of judul -->

            <!-- form data -->
            <form
                @submit.prevent="store"
                @keydown="form.onKeydown($event)"
                autocomplete="off"
            >
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >No. SPTRD <span class="text-danger">*</span></label
                    >
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-sm-6">
                                <v-select
                                    label="no_spt"
                                    :reduce="no_spt => no_spt.id"
                                    :options="Sptrd.dataSpt"
                                    v-model="form.spt_id"
                                    placeholder="Pilih SPTRD"
                                    @input="inputSpt(form.spt_id)"
                                    :disabled="isDisable"
                                ></v-select>
                                <em
                                    v-if="form.errors.has('spt_id')"
                                    class="form-text text-danger"
                                    ><i class="fa fa-exclamation-triangle"></i>
                                    {{ errors.spt_id[0] }}</em
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Surat <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-sm-6">
                                <v-select
                                    label="no_surat"
                                    :reduce="no_surat => no_surat.id"
                                    :options="Sptrd.dataSurat"
                                    placeholder="Pilih Surat"
                                    v-model="form.surat_id"
                                    :disabled="isDisable"
                                ></v-select>
                                <em
                                    v-if="form.errors.has('surat_id')"
                                    class="form-text text-danger"
                                    ><i class="fa fa-exclamation-triangle"></i>
                                    {{ errors.surat_id[0] }}</em
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Keterangan <span class="text-danger">*</span></label
                    >
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-sm-12">
                                <b-form-textarea
                                    id="textarea-large"
                                    size="lg"
                                    placeholder="Masukkan Keterangan"
                                    :class="{
                                        'is-invalid': form.errors.has(
                                            'keterangan'
                                        )
                                    }"
                                    v-model="form.keterangan"
                                    :disabled="isDisable"
                                ></b-form-textarea>
                                <em
                                    v-if="form.errors.has('keterangan')"
                                    class="form-text text-danger"
                                    ><i class="fa fa-exclamation-triangle"></i>
                                    {{ errors.keterangan[0] }}</em
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="pt-2">
                    <b-row>
                        <b-col>
                            <span>
                                <b-button
                                    variant="primary"
                                    type="submit"
                                    :disabled="simpanDisable"
                                >
                                    <span v-if="isLoading">
                                        <b-spinner
                                            variant="primary"
                                            label="Spinning"
                                            small
                                        ></b-spinner>
                                    </span>
                                    <i class="fa fa-save" v-else></i> Simpan
                                </b-button>
                            </span>
                            <span class="mx-2">
                                <b-button variant="secondary" @click="clear">
                                    <i class="fa fa-redo-alt"></i> Clear
                                </b-button>
                            </span>
                        </b-col>
                        <b-col v-if="lihatVar">
                            <span class="float-right">
                                <b-button
                                    variant="success"
                                    @click="showPdf(idKodeBayar)"
                                >
                                    <i class="fa fa-check-circle"></i> Lihat
                                    Kode Bayar
                                </b-button>
                            </span>
                        </b-col>
                    </b-row>
                </div>
            </form>
            <!-- end of form-->
        </panel>
        <b-modal
            v-model="showLoader"
            id="modalLoader"
            hide-footer
            hide-header
            no-close-on-backdrop
            centered
            size="sm"
        >
            <div class="text-center">
                <b-spinner variant="light" label="Text Centered"></b-spinner>
                <h5>Harap Menunggu...</h5>
                <div>Data Anda Sedang Di Proses</div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapState } from "vuex";
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import HRetribusi from "../../../components/npwr/HRetribusi.vue";
import axios from "axios";
import hapusStorage from "@/helper/hapusLocalStore";

export default {
    mixins: [hapusStorage],
    components: { HRetribusi },
    data() {
        return {
            form: new Form({
                spt_id: "",
                surat_id: "",
                keterangan: ""
            }),
            errors: [],
            isLoading: false,
            simpanDisable: false,
            lihatVar: false,
            showLoader: false,
            pdfUrl: "",
            isDisable: false
        };
    },
    computed: {
        ...mapState(["Sptrd"])
    },
    watch: {
        $route(to, from) {
            if (this.$route.name === "BuatKodeBayar") {
                this.form.reset();
                this.form.clear();
            }
        }
    },
    mounted() {
        this.$store.dispatch("Sptrd/sptStore");
        if (this.$route.params.id) {
            this.dataKodeBayar(this.$route.params.id);
        }
    },
    methods: {
        store() {
            this.isLoading = true;
            this.simpanDisable = true;
            this.isDisable = true;
            this.form
                .post("/api/transaksi/kode-bayar")
                .then(response => {
                    this.isLoading = false;
                    this.idKodeBayar = response.data.data.no_sts;
                    this.lihatVar = true;
                    this.simpanDisable = true;
                    this.isDisable = false;
                    this.$swal({
                        title: "KODE BAYAR BERHASIL DIBUAT",
                        text:
                            'Untuk melihat kode bayar yang sudah dibuat, silahkan lihat pada halaman List Kode Bayar."',
                        icon: "success",
                        showCloseButton: true,
                        timer: 5000
                    });
                })
                .catch(error => {
                    this.isLoading = false;
                    this.idKodeBayar = "";
                    this.lihatVar = false;
                    this.simpanDisable = false;
                    this.isLoading = false;
                    this.isDisable = false;
                    if (error.response.status === 422) {
                        this.errors = error.response.data;
                    } else if (error.response.status === 401) {
                        // hapus storage mixins
                        this.clearAll();
                    } else if (error.response.status === 403) {
                        this.$swal({
                            title: "TERJADI MASALAH",
                            text: error.response.data.status.message,
                            icon: "error",
                            showCloseButton: true,
                            timer: 5000
                        });
                    } else {
                        this.$swal({
                            title: "TERJADI MASALAH",
                            text: "Silahkan hubungi Administrator Retribusi Daerah.",
                            icon: "error",
                            showCloseButton: true,
                            timer: 5000
                        });
                    }
                });
        },

        // data detail kode bayar
        dataKodeBayar(id) {
            axios
                .get("/api/transaksi/kode-bayar/" + id)
                .then(response => {
                    const items = response.data.data;
                    this.form.fill(items);
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        // hapus storage mixins
                        this.clearAll();
                    }
                });
        },

        // input surat berdasarkan spt_id
        inputSpt(id) {
            this.$store.dispatch("Sptrd/suratStore", id);
        },
        clear() {
            this.form.reset();
            this.form.clear();
            this.simpanDisable = false;
            this.lihatVar = false;
            this.idKodeBayar = "";
            this.$store.dispatch("Sptrd/sptStore");
        },
        showPdf(id) {
            this.getPdf(id);
        },
        // close modal
        closeModal() {
            this.$root.$emit("bv::hide::modal", "modal-1");
            this.showModalData = false;
            this.pdfUrl = null;
        },
        // view pdf
        getPdf(id) {
            this.showLoader = true;
            axios
                .get("/api/view-pdf/surat/kode-bayar/by-sts/" + id, {
                    responseType: "arraybuffer",
                    timeout: 5000
                })
                .then(response => {
                    this.showLoader = false;
                    this.pdfUrl = window.URL.createObjectURL(
                        new Blob([response.data], { type: "application/pdf" })
                    );
                    window.open(this.pdfUrl, "_blank");
                })
                .catch(error => {
                    this.showLoader = false;
                    if (error.response.status === 401) {
                        // hapus storage mixins
                        this.clearAll();
                    }
                });
        }
    }
};
</script>
